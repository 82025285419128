<template>
<h1 class="page-header" v-if="county">
<button class="btn btn-default ml-5" @click="$router.go(-1)" style="margin-right:13px; margin-bottom:7px; width:70px"><i class="fas fa-angle-left"></i></button>Manage Divisions in  {{ county.title }}
</h1>
<hr class="mb-4" />


<div class="row">

<div class="card">
<ul class="nav nav-tabs nav-tabs-v2 px-4">
<li class="nav-item me-3"><a href="#allTab" class="nav-link active px-2" data-bs-toggle="tab">All</a></li>
<li class="nav-item me-3"><a href="#publishedTab" class="nav-link px-2" @click.prevent="changefilterlist('active')">Active </a></li>
<li class="nav-item me-3"><a href="#expiredTab" class="nav-link px-2" @click.prevent="changefilterlist('suspended')">Suspended</a></li>
</ul>


<div class="tab-content p-4">
<div class="tab-pane fade show active" id="allTab">

<div class="input-group mb-4">
<div class="flex-fill position-relative">
<div class="input-group">
<input type="text" v-model.trim="search" class="form-control ps-35px" placeholder="Search divisions in this county" />
<div class="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style="z-index: 1020;">
<i class="fa fa-search opacity-5"></i>
</div>
</div>
</div>
</div>


<div class="table-responsive">
<table class="table table-hover text-nowrap">
<thead>
<tr>
<th class="border-top-0 pt-0 pb-2">#</th>
<th class="border-top-0 pt-0 pb-2">Division Title</th>
<th class="border-top-0 pt-0 pb-2">Parishes</th>
<th class="border-top-0 pt-0 pb-2">Villages</th>
<th class="border-top-0 pt-0 pb-2">Population</th>
<th class="border-top-0 pt-0 pb-2">Status</th>
<th class="border-top-0 pt-0 pb-2">Action</th>
</tr>
</thead>
<tbody v-if="divisions">
<tr v-if="division" >
    <td colspan="6">
        <input v-model="division.title" class="form-control ps-35px" placeholder="Add division title. Will be added under this division" />
        <i style="margin-left:10px" class="text-danger" v-if="formerror.title">{{ formerror.title }}</i>
    </td>
    <td>
        <button v-if="loading == division.id" class="btn btn-primary rounded" type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Loading...
        </button>
        <button v-else class="btn btn-primary rounded mr-2 " style="margin-right:5px" @click.prevent="updatedivisiondetails(division.id)">Update </button>
        <button  class="btn btn-danger rounded  " @click.prevent="cancelupdate">Cancel </button>
    </td>
</tr>
<tr v-else >
    <td colspan="6">
        <input v-model="forminput.title" class="form-control ps-35px" placeholder="Add division title. Will be added under this division" />
        <i style="margin-left:10px" class="text-danger" v-if="formerror.title">{{ formerror.title }}</i>
    </td>
    <td>
        <button v-if="loading == 'savedivision'" class="btn btn-primary rounded" type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Loading...
        </button>
        <button v-else class="btn btn-primary rounded  " @click.prevent="createnewdivision">Save</button>
    </td>
</tr>

<tr v-for="(item, index) in filteredlist" :key="item.id" style="cursor:pointer !important" >

    <td class="align-middle">{{ index + 1}}</td>
    <td class="align-middle"><router-link :to="{name:'divisionparishes', params:{id:item.id}}">{{ item.title }}</router-link></td>
    <td class="align-middle" >{{ item.parishes_count }}</td>
    <td class="align-middle" >{{ item.villages_count }}</td>
    <td class="align-middle" >{{ item.collections_count }}</td>
    <td class="align-middle" >
        <button v-if="item.status == 1" @click.prevent="changeitemstatusbtn(item.id)" class="badge btn btn-teal  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Suspend</button>
        <button v-if="item.status == 0" @click.prevent="changeitemstatusbtn(item.id)" class="badge btn btn-indigo  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Unsuspend</button>
    </td>


    <td class="align-middle">
  
    <button @click.prevent="getsingledivision(item.id)"  class="badge btn btn-warning  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" style="margin-right:6px">Update</button>

    <button @click.prevent="deletesingledivision(item.id)"  class="badge btn btn-danger  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" style="margin-right:6px">Delete</button>
   </td>

</tr>




</tbody>
</table>
</div>


<div class="d-md-flex align-items-center" v-if="divisions">
<div class="me-md-auto text-md-left text-center mb-2 mb-md-0">
Showing {{ divisions.data.length}} to {{ divisions.per_page}} of {{ divisions.total}} entries
</div>
<ul class="pagination mb-0 justify-content-center">

<li class="page-item" v-for="(link, index) in divisions.links" :key="link.id">
    <a v-if="divisions.last_page >= index && index != 0 " @click.prevent="getpagelist(index)" class="page-link" href="#">{{ index}}</a>
</li>

</ul>
</div>



</div>
</div>
</div>



</div>

</template>
<script>
import {mapGetters, mapActions } from 'vuex'
export default {

    data(){
        return{
            forminput:{
                title: '',
                county_id :''
            },
            formerror:{
                title: '',
                county_id :''
            },

            lastpage:'',
            currentpage:'',
            nextpage:'',
            filtertype:'',
            search:'',
            county_id :''
        }
    },



     watch:{
		search(){
			if(this.search.length){
				if(this.search.length > 2){
					this.seracheddivisions()
				}else{

                  this.getpagelist()  
                }
			}else{
				this.getpagelist()
			}
		}
	},



     computed:{
        ...mapGetters(['msg', 'error','pageloader','loading', 'division','divisions', 'county']),

            filteredlist(){
                if(this.filtertype == 'active'){
                    return this.divisions.data.filter(co => co.status == 1)
                }else if(this.filtertype == 'suspended'){
                    return this.divisions.data.filter(co => co.status == 2)
                }else {
                    return this.divisions.data
                }
            },
        },


    methods:{
        ...mapActions(['savedivision','getdivisionlist', 'getcounty','updatedivision','getseracheddivisions','getdivision','getcountydivisions','changedivisionstatus','deletedivision']),

        createnewdivision(){
             if(this.forminput.title == ''){
                this.formerror.title = 'division Name is Required';
                setTimeout(() => this.formerror.title = '', 3000)
            }else if(this.county_id == ''){
                this.county_id  = 'county  is Required';
                setTimeout(() => this.county_id  = '', 3000)
            }else{
            let data = {
                title:this.forminput.title,
                county_id:this.county_id 
            }
            this.savedivision(data).then(()=>{
                this.getpagelist();
                this.forminput.title = '';
                
            })
            }

        },

        getsingledivision(id){
            this.getdivision(id)
        },

        deletesingledivision(id){
            this.deletedivision(id)
        },

        changeitemstatusbtn(id){
            this.changedivisionstatus(id)
        },

        updatedivisiondetails(){

             if(this.division.title == ''){
                this.formerror.title = 'division Name is Required';
                setTimeout(() => this.formerror.title = '', 3000)
            }else if(this.county_id == ''){
                this.county_id  = 'division  is Required';
                setTimeout(() => this.county_id  = '', 3000)
            }else{
            let data = {
                title:this.division.title,
                county_id:this.county_id 
            }
            let id = this.division.id;
            this.updatedivision({id, data}).then(()=>{
                this.getpagelist();
                this.county = '';          
            })
            }

        },

        cancelupdate(){
            this.division = '';
        },

        seracheddivisions(){
            let data = {
                search:this.search,
                county_id :this.county_id 
            }
            this.getseracheddivisions(data).then(()=>{

            })
	    },


         nextpagedetails(page){
            let id = this.county_id ;
            this.currentpage = page;
            this.getcountydivisions({id ,page});
        },

        previouspagedetails(page){
            let id = this.county_id ;
            this.currentpage = page;
            this.getcountydivisions({id, page});
        },


        getpagelist(page){
            let id = this.county_id ;
            this.currentpage = page;
            this.getcountydivisions({id, page});
        }



    },

    
    created(){
        let id = this.$route.params.id; 
        this.county_id  = id;
        this.getcounty(id);
        this.getpagelist();
        
    }

}
</script>